.QR {
    margin: 0 auto;
    margin-top: 2em;
    margin-bottom: 2em;
}

.status {
    margin-top: 1em;
    text-align: center;
}

.status p {
    padding: .5em;
    color: rgba(var(--brand-dark-rgb), .85);
}